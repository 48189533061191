<template>
  <el-dialog v-dialogDrag title="查看扩容记录" :visible.sync="visible" width="1000px">
    <el-table border :data="list" v-loading="loading">

      <el-table-column align="center" prop="creator" label="创建人" />
      <el-table-column align="center" prop="historySpace" label="历史空间" />
      <el-table-column align="center" prop="fileSpace" label="扩容空间" />
      <el-table-column align="center" prop="createDate" label="扩容时间" />
    </el-table>
    <div class="pageBox">
      <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit="params.limit" :total="total" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" @click="$emit('update:show',false)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number | String,
      default: false,
    },
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
      },
      list: [],
      loading: false,
      total: 0,
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {
    id() {
      this.search();
    },
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      if (!this.id) return;
      this.params.page = 1;
      this.params.tenantCode = this.id;
      this.getList();
    },
    async getList() {
      this.loading = true;

      var res = await this.$get(
        "/platform-admin/filespaceexpansionrecord/page",
        this.params
      );
      this.loading = false;
      if (res && res.code === 0) {
        this.list = res.data.list;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: 350px;
}
.pageBox > div {
  display: inline-block;
}
.pageBox {
  text-align: center;
}
</style>