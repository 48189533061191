<template>
  <jf-layout>
    <template slot="head">
      <el-form class="form" :inline="true">
        <el-form-item label="">
          <el-input size="small" v-model="params.companyName" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </template>

    <el-table slot="body" border :data="list" v-loading="loading">
      <el-table-column align="center" prop="companyName" label="公司全称" />
      <el-table-column align="center" prop="companyAbbreviation" label="公司简称" />
      <el-table-column align="center" prop="storageSpaceQuota" label="空间配额(G)" />
      <el-table-column align="center" :formatter="surplusCapacity" label="剩余容量(G)" />
      <el-table-column align="center" label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" v-if="isAuth('platform-admin:tenant:expansion')"  @click="add(row)">空间扩容</el-button>
          <el-button size="mini" v-if="isAuth('tenant-download-centre-fileSpaceExpansionRecord:filespaceexpansionrecord:page')&&isAuth('tenant-download-centre-fileSpaceExpansionRecord:filespaceexpansionrecord:info')" @click="see(row)">查看扩容记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit="params.limit" :total="total" />
    <Record :show.sync="show" :id="tenantCode" />
  </jf-layout>
</template>

<script>
import Record from "./record";
export default {
  components: { Record },
  data() {
    return {
      show: false,
      params: {
        page: 1,
        limit: 10,
      },
      list: [],
      loading: false,
      total: 0,
      tenantCode: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    surplusCapacity(row){
      if(row.surplusCapacity){
        return (row.surplusCapacity/1024/1024/1024).toFixed(2)
      }else{
        return ''
      }
    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      var res = await this.$get("/platform-admin/tenant/page", this.params);
      this.loading = false;
      if (res && res.code === 0) {
        this.list = res.data.list;
        this.total = res.data.total;
      }
    },
    add(row) {
      this.$confirm(` 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
      this.$prompt(
        `将为『${row.companyName}』进行文件存储扩容(G)`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^\+?[1-9][0-9]*$/,
          inputErrorMessage: "格式不正确",
        }
      )
        .then(async ({ value }) => {
         var res = await this.$put(
            "/platform-admin/tenant/expansion",
            {
              id: row.id,
              storageSpaceQuota: Number(value),
            }
          );
          if(res && res.code===0){
            this.$message.success("扩容成功！");
            this.getList()
          }
        })
        .catch(() => {});
    },
    see(row) {
      // 传给子组件的 tenantCode;
      this.tenantCode = Number(row.tenantCode);
      this.show = true;
    },
  },
};
</script>
